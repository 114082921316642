/**
 * Development is localhost
 * Staging is the QA environment deployed as Production
 *
 * For Development debugging is enabled
 * For Development & Staging we use development database
 * For Production we use production database, no debugging
 */

export const isDev = process.env.NODE_ENV !== "production";

export const isStaging = process.env.GATSBY_ENVIRONMENT_NAME === "staging";

export const isProd = !isDev && !isStaging;
