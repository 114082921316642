import React from "react";

// typings

// components
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";

interface Props {
  name: string;
  id: string;
  type: "lab" | "receiver";
}

const LaboratoryCard = (props: Props): JSX.Element => {
  const urlRedirect = props.type === "lab" ? "laboratory" : "receiver";
  return (
    <Card variant="outlined">
      <Link
        to={`/${urlRedirect}/${props.id}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <CardActionArea style={{ height: "100%" }}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              {props.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default LaboratoryCard;
