import { AxiosResponse } from "axios";
import qs from "qs";

import api from "../api";
import { Locale } from "../../interfaces/Locale";
import { CartItemObjectType } from "../../interfaces/cartItem";
import { GetItemsApiServiceProps, RequestResponse } from "../../interfaces/request";
import { parseItems } from "../../parser/services";
import { GetPaginatedRawDataItemsPayload } from "../../interfaces/Services";

const labsBaseUrl = "labs";
const labServicesBaseUrl = "lab-services";


type filterByCategoryProps = {
  category?: string;
  allow_zero_price?: boolean;
}
class LabService {
  id: any;
  createLab(data: Object): Promise<AxiosResponse> {
    return api.post(`${labsBaseUrl}/`, (data = data));
  }

  updateLab(id: string, data: Object): Promise<AxiosResponse> {
    return api.patch(`${labsBaseUrl}/${id}/`, (data = data));
  }

  fetchLabs(page_size?: number): Promise<AxiosResponse> {
    return api.get(`${labsBaseUrl}`, { params: { page_size } });
  }

  fetchLab(id: string): Promise<AxiosResponse> {
    return api.get(`${labsBaseUrl}/${id}/`);
  }


  addLabAdmin(lab_id: string, data: Object): Promise<AxiosResponse> {
    return api.post(
      `${labsBaseUrl}/${lab_id}/create_access_user/`,
      (data = data)
    );
  }

  fetchLabServices(lab_id: string): Promise<AxiosResponse> {
    return api.get(`${labServicesBaseUrl}/filter_by_lab/`, {
      params: { lab: lab_id },
    });
  }

  createLabService(data: Object): Promise<AxiosResponse> {
    return api.post(`${labServicesBaseUrl}/`, (data = data));
  }

  updateLabService(
    lab_service_id: string,
    data: Object
  ): Promise<AxiosResponse> {
    return api.patch(`${labServicesBaseUrl}/${lab_service_id}/`, (data = data));
  }

  deleteLabService(lab_service_id: string): Promise<AxiosResponse> {
    return api.delete(`${labServicesBaseUrl}/${lab_service_id}/`);
  }

  filterByCategory(params?: filterByCategoryProps): Promise<AxiosResponse> {
    return api.get(`${labServicesBaseUrl}/filter_by_category/`, { params });
  }

  fetchOrderedServices = (locale?: Locale, comuna?: string) => {
    return api.get(`${labServicesBaseUrl}/ordered_available_services/`, { params: { country: locale, comuna } });
  }

  fetchAvailableServices = async ({
    signal,
    params,
  }: GetItemsApiServiceProps): Promise<RequestResponse<CartItemObjectType[]>> => {
    const res = await api.get<GetPaginatedRawDataItemsPayload>(
      `/v2/items/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      { signal },
    );
    const data = parseItems(res.data.results.filter(({ price }) => price));
    return { data };
  }
}

const labService = new LabService();

export default labService;
