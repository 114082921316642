import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import labService from "../../utils/api/v1/labService";
import { receiverAPI } from "../../utils/api/v2";
// typings
import { Laboratory } from "../../utils/interfaces/Laboratory";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import LoadingError from "../../components/Loaders/LoadingError";
import Flex from "../../components/Containers/Flex";
import LaboratoryCard from "../../components/Laboratory/LaboratoryCard";
import Button from "@material-ui/core/Button";
import { Box, Grid, TablePagination, Switch } from "@material-ui/core";
import api from "../../utils/api/api";
import { isProd } from "../../utils/environment";

const LaboratoryList = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [labs, setLabs] = useState<Laboratory[]>([]);
  const [receivers, setReceivers] = useState<any[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [prevPage, setPrevPage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [type, setType] = useState<"receiver" | "lab">("lab");

  const fetchData = async (): Promise<void> => {
    setLoading(true);

    try {
      const req = await labService.fetchLabs();
      const receiverReq = await receiverAPI.list();
      setLabs(req.data.results);
      setReceivers(receiverReq.data);
      const next_url = isProd
        ? req.data.next?.replace("http", "https")
        : req.data.next;
      const previous_url = isProd
        ? req.data.previous?.replace("http", "https")
        : req.data.previous;
      setNextPage(next_url);
      setPrevPage(previous_url);
      setTotalPages(req.data.count);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "get",
        baseURL: "",
        url: action === "next" ? nextPage : prevPage,
      });
      setLabs(request.data.results);
      setTotalPages(request.data.count);
      setNextPage(request.data.next?.replace("http://", "https://"));
      setPrevPage(request.data.previous?.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    if (type === "lab") {
      fetchNewPage(action);
    }
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{type === "lab" ? "Laboratorio" : "Receptor"} </Grid>
            <Grid item>
              <Switch
                color="primary"
                checked={type === "receiver"}
                onChange={() => {
                  setType(type === "lab" ? "receiver" : "lab");
                }}
              />
            </Grid>
            <Grid item>Receptor</Grid>
          </Grid>
          <Flex align="center" justify="space-between" margin="0 0 2rem 0">
            <h1>Laboratorios</h1>
            <Link
              to={`/${type == "lab" ? "laboratory" : "receiver"}/new/`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" size="large" color="primary">
                Crear nuevo
              </Button>
            </Link>
          </Flex>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            style={{ gap: "5px" }}
          >
            {type == "lab" &&
              labs.map((lab: Laboratory) => (
                <Box gridColumn="span 3" key={lab.id}>
                  <LaboratoryCard
                    name={lab.display_name}
                    id={lab.id}
                    type="lab"
                  />
                </Box>
              ))}
            {type == "receiver" &&
              receivers.map((receiver: any) => (
                <Box gridColumn="span 3" key={receiver.id}>
                  <LaboratoryCard
                    name={receiver.name}
                    id={receiver.id}
                    type="receiver"
                  />
                </Box>
              ))}
          </Box>
          <TablePagination
            component="div"
            count={totalPages}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={currentPage}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default LaboratoryList;
